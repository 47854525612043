import React, { useEffect, useState } from 'react'

import * as styles from './cloudIndexTable.module.css'

const CloudIndexTable = ({ headers, data, inverse, tight }) => {
  if (!headers || !data) return null
  const [outputData, setOutputData] = useState(sortND(data))
  const [orderBy, setOrderBy] = useState({})
  const [isOpen, setOpen] = useState(null)

  const currency = (num) => {
    if (num === null || num === '') return 'ND'

    if (typeof num !== 'number' || isNaN(num)) return num

    // Alter numbers larger than 1k
    if (num >= 1e3) {
      var units = ['k', 'M', 'B', 'T']

      var order = Math.floor(Math.log(num) / Math.log(1000))

      var unitname = units[(order - 1)]
      var shortened = (num / 1000 ** order).toFixed(1)

      // output number remainder + unitname
      return `$${shortened}${unitname}`
    }
    // return formatted original number
    // const output = num.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    const output = `$${num.toFixed(1)}`
    return output
  }

  const outputComponent = (component, data) => {
    if (component !== null && typeof component !== 'undefined') {
      const Output = component
      return <div className={styles.logo}><Output /></div>
    }

    return <>{data.company || ''}</>
  }

  function sortND (data) {
    const sorted = data.sort((a, b) => {
      if (a.totalRaisedToDate === 'ND') return 1
      if (b.totalRaisedToDate === 'ND') return -1
      return 0
    })
    return sorted
  }

  useEffect(() => {
    const sortedND = sortND(data)
    setOutputData(sortedND)
  }, [data])

  const parseValue = (value) => {
    if (typeof value === 'string') {
      value = value.replace('$', '').replace(/,/g, '')
      if (value.endsWith('M')) {
        return parseFloat(value.replace('M', '')) * 1e6
      } else if (value.endsWith('B')) {
        return parseFloat(value.replace('B', '')) * 1e9
      }
    }
    return parseFloat(value) || 0
  }

  const actionOrderBy = (_field, order) => {
    const newOrderBy = {}
    const stages = { Early: 0, Mid: 1, Late: 2 }
    const field = _field === 'logo' ? 'company' : _field

    if (_field in orderBy && orderBy[_field] === order) {
      newOrderBy[_field] = null
      setOrderBy(newOrderBy)
      const firstColumn = Object.keys(data[0])[0]
      const newData = data.sort((a, b) => {
        if (Number.isInteger(a[firstColumn])) {
          return a[firstColumn] - b[firstColumn]
        } else {
          if (a[firstColumn].toLowerCase() < b[firstColumn].toLowerCase()) {
            return -1
          }
          if (a[firstColumn].toLowerCase() > b[firstColumn].toLowerCase()) {
            return 1
          }
          return 0
        }
      })
      setOutputData(newData)
      return true
    }

    newOrderBy[_field] = order
    setOrderBy(newOrderBy)

    const sortFunction = (a, b) => {
      if (Number.isInteger(a[field]) || (field === 'totalFunding' || field === 'totalRaisedToDate' || field === 'marketCap')) {
        return parseValue(a[field]) - parseValue(b[field])
      } else if (field === 'stage') {
        return stages[a[field]] - stages[b[field]]
      } else {
        if (a[field].toLowerCase() < b[field].toLowerCase()) {
          return -1
        }
        if (a[field].toLowerCase() > b[field].toLowerCase()) {
          return 1
        }
        return 0
      }
    }

    if (order === 'asc') {
      const newData = data.sort((a, b) => sortFunction(a, b))
      setOutputData(newData)
      return true
    }

    if (order === 'desc') {
      const newData = data.sort((a, b) => sortFunction(b, a))
      setOutputData(newData)
      return true
    }

    return true
  }


  const toggleOpen = (rowId) => {
    if (rowId === isOpen) {
      setOpen(null)
    } else {
      setOpen(rowId)
    }
  }

  function generateGridTemplateColumns (headers) {
    if (inverse) {
      return `188px repeat(${headers.length - 1}, 1fr)`
    }

    const totalWidth = {
      px: 1224,
      per: 100
    }
    const fixedWidths = {
      // CEO: 354 / totalWidth.px * totalWidth.per,
      CEO: '1fr',
      Subcategory: 200 / totalWidth.px * totalWidth.per + '%'
    }
    const firstColumnWidth = 188 / totalWidth.px * totalWidth.per
    const secondColumnWidth = 160 / totalWidth.px * totalWidth.per

    return headers.map((header, index) => {
      if (index === 0) {
        return `${firstColumnWidth}%`
      }
      if (index === 1) {
        return `${secondColumnWidth}%`
      }

      if (fixedWidths[header.label]) {
        return `${fixedWidths[header.label]}`
      }

      if (header.orderby) {
        return '115px'
      }

      return '100px'
    }).join(' ')
  }

  const gridTemplateColumns = generateGridTemplateColumns(headers)

  return (
    <div className={`${styles.table} ${inverse && styles.inverse}`}>
      <div className={styles.headers} style={{ gridTemplateColumns: `${gridTemplateColumns}` }}>
        {headers.map((h, hI) =>
          <div key={hI} className={`${styles.label} ${'orderby' in h ? styles.ordered : ''}`}>
            {'orderby' in h && (
              <>
                <span className={`${styles.orderby} ${styles.desc} ${orderBy[h.data] === 'desc' && styles.active}`} onClick={e => actionOrderBy(h.data, 'desc')} />
                <span className={`${styles.orderby} ${styles.asc} ${orderBy[h.data] === 'asc' && styles.active}`} onClick={e => actionOrderBy(h.data, 'asc')} />
              </>
            )}
            {h.label}
          </div>
        )}
      </div>
      <div className={styles.rows}>
        {outputData.map((d, dI) => (
          <div key={dI} className={`${styles.row} ${tight && styles.tight} ${isOpen === dI && styles.open}`} style={{ gridTemplateColumns: `${gridTemplateColumns}` }} onClick={e => toggleOpen(dI)}>
            {headers.map((h, hI) =>
              <div key={hI} className={styles.cell}>
                {(h.label !== '' && h.label !== 'Logo' && h.label !== 'Company') && <span className={styles.label}>{h.label}: </span>}
                {('format' in h && h.format === 'currency') && currency(d[h.data])}
                {('format' in h && h.format === 'component') && outputComponent(d[h.data], d)}
                {!('format' in h) && d[h.data]}
              </div>
            )}
            {('portfolio' in d && d.portfolio !== null) && (<span className={styles.asterisk}>*</span>)}
            {'url' in d && (<a href={`https://${d.url}`} target='_blank' rel='noreferrer' />)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CloudIndexTable
